/* You can add global styles to this file, and also import other style files */
@import 'theme.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.title, .subtitle, .horizontal-menu, mat-card-title {
  font-family: Raleway, "Helvetica Neue", sans-serif;
}

// GLOBAL VARS
:root {
  --headerBackground: url('https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/Background%2FHelperApp_ProfielBanner_130120-01.png?alt=media&token=cb1797ba-119c-465f-802c-be025d98b6c4');
  --primary: #40539f;
  --accent: #8cbf4d;
  // --accent:
  --loginImage : url('https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/logo%2Fhelper%20icon.png?alt=media&token=06265c16-613b-4459-95d6-80241ee4e6ce');
  --loginVideo : url('https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/Background%2FHelperApp_Banner.mp4?alt=media&token=01c88265-dd41-48dc-86df-dca8f2d63cf6');
}
.expension {
  padding-bottom: 20px;
}
.outline {
  border: 1px solid #E0E0E0;
  box-shadow: none !important;
}
// @media only screen and (max-width: 600px) {
//   .mat-dialog-container {
//     padding: 0 !important;
//     border-radius: 0px !important;
//   }
// }
.app-full-screen-dialog .mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
}
/* WebKit and Blink */
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 599px) {
  .page {
    margin-bottom: 56px;
  }
}

@media print {
  .mat-sidenav-content {
    background-color: white;
  }

  .bottom-navigation {
    visibility: hidden;
  }
}

.ngx-timepicker {
  border: 1px solid #E0E0E0;
  border-radius: 6px;
}
